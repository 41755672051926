import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { useMeasure } from "react-use";
import parse from 'html-react-parser';
import style from "./accordion.module.scss";

const Accordion = (props) => {
	const defaultHeight = "0px";

	// Manages the open or cloased state of the accordion
	const [open, toggle] = useState(false);

	// The height of the content inside of the accordion
	const [contentHeight, setContentHeight] = useState(defaultHeight);

	// Gets the height of the element (ref)
	const [ref, { height }] = useMeasure();

	// Animations
	const expand = useSpring({
		height: open ? `${contentHeight}px` : defaultHeight
	});

	useEffect(() => {
		//Sets initial height
		setContentHeight(height + 20);

		//Adds resize event listener
		window.addEventListener("resize", setContentHeight(height + 20));

		// Clean-up
		return window.removeEventListener("resize", setContentHeight(height + 20));
	}, [height]);

	const title = props.title;
	const body = props.body;
	if (body !== null && body !== '') {
		return (
			<div className={style.container}>
					<div onClick={() => toggle(!open)} ref={ref} className={`${style.title} ${style.accordionTitle} ${open ? style.open : style.closed}`}>
						{`[ ${open ? "-" : "+"} ]`} {title}
					</div>
				<animated.div className={style.body} style={expand}>
					<div ref={ref} className={style.content}>
						{parse(body)}
					</div>
				</animated.div>
			</div>
		)
	} else {
		return (
			<div className={style.container}>
				<div className={`${style.title}`}>{title}</div>
			</div>
		)
	}
};

export default Accordion;